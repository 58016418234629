import React, { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import { Helmet } from "react-helmet";

import HeroBen from "@/assets/backgrounds/hero-ben.png";

import SpotifyLogo from "@/assets/logos/color/spotify-color.png";
import AppleMusicLogo from "@/assets/logos/color/apple-music-color.png";
import TripleJLogo from "@/assets/logos/color/triple-j-color.png";
import InstagramLogo from "@/assets/logos/color/instagram-color.png";
import FacebookLogo from "@/assets/logos/color/facebook-color.png";

import BenFireplace from "@/assets/images/ben-fireplace.jpeg";
import BenGuitar from "@/assets/images/ben-guitar.jpeg";
import BenGuitarColor from "@/assets/images/ben-guitar-color.jpeg";
import PalmTreeShadows from "@/assets/images/palm-tree-shadows.jpeg";
import PalmTrees from "@/assets/images/palm-trees.jpeg";
import BenTree from "@/assets/images/ben-tree.jpeg";

import { NavHeader } from "@/features/NavHeader";
import classNames from "classnames";
import { IconLink } from "@/widgets/IconLink";

import MobileEv from "@/assets/logos/Mobile-Evergreen-Icon.png";
import DeskEv from "@/assets/logos/Desktop-Evergreen-Icon.png";

const Landing = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
    strategy: "absolute",
  });

  return (
    <>
      <section className="min-w-[30vh] md:min-h-screen hero-bg pb-12 md:pb-0">
        <Helmet>
          <head></head>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-6V4Y6RVYWP"></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-6V4Y6RVYWP');
            `}
          </script>
        </Helmet>
        <NavHeader color="white" />

        <div className="w-full flex flex-col justify-center items-center px-2">
          <div className="w-full flex items-center justify-center mb-2 mt-2">
            <span className="bg-bm-tan inline-block w-3 md:w-5 h-3 md:h-5 rounded-full mr-2" />
            <h1 className="text-white text-2xl md:text-5xl italic font-goon font-semibold">
              Debut Album{" "}
            </h1>
            <span className="bg-bm-tan inline-block w-3 md:w-5 h-3 md:h-5 rounded-full ml-3" />
          </div>

          <div className="group relative w-[74vh] h-[72vh] max-w-full md:max-w-3xl album-bg overflow-hidden">
            <div className="blur hidden group-hover:block" />

            <div className="flex justify-between items-center absolute w-full px-3 pt-2">
              <h1 className={classNames("font-goon text-3xl", "text-white")}>
                Begin Again
              </h1>

              <h1 className={classNames("font-goon text-3xl", "text-white")}>
                Ben Morgan
              </h1>
            </div>

            <div
              className={classNames(
                "absolute",
                "w-full",
                "h-full",
                "flex",
                "flex-col",
                "justify-center",
                "items-center",
                "opacity-0 group-hover:opacity-100",
                "space-y-6",
                "cursor-pointer"
              )}
            >
              <h1 className={classNames("font-goon text-3xl", "text-white")}>
                So The Story Starts
              </h1>

              <h1 className={classNames("font-goon text-3xl", "text-white")}>
                Journey To Nowhere
              </h1>

              <h1 className={classNames("font-goon text-3xl", "text-white")}>
                Unforgettable
              </h1>

              <h1 className={classNames("font-goon text-3xl", "text-white")}>
                As The Waves Crash Into Time
              </h1>

              <h1 className={classNames("font-goon text-3xl", "text-white")}>
                Begin Again
              </h1>
            </div>
          </div>

          <div className="mt-3 space-x-8">
            <button
              ref={setReferenceElement}
              className="font-goon text-2xl border-2 border-bm-tan bg-[#8E8982] text-white py-2 md:py-3 pb-1.5 md:pb-2.5 px-12 rounded-lg"
              onClick={() => setShowMenu((x) => !x)}
            >
              Stream Now
            </button>

            <a
              href="/artboard"
              className="hidden md:inline-block font-goon text-2xl border-2 border-bm-tan bg-[#8E8982] text-white py-3 pb-2.5 px-12 rounded-lg"
            >
              Art Board
            </a>
          </div>
        </div>

        {showMenu && (
          <div
            ref={setPopperElement}
            style={styles.popper}
            className="bg-white flex flex-col items-center mb-2 border-b-8 border-bm-tan rounded-sm overflow-hidden"
            {...attributes.popper}
          >
            <a
              href="https://open.spotify.com/artist/0BGZx1OzVJ5uwHK4Ke5mNr"
              className="p-3 px-6 border-b-2 border-bm-tan hover:bg-stone-100 h-20"
            >
              <img
                src={SpotifyLogo}
                className={classNames("w-12 h-12", "shrink-0")}
              />
            </a>

            <a
              href="https://music.apple.com/au/artist/ben-morgan/1552341609"
              className="p-3 px-6 border-b-2 border-bm-tan hover:bg-stone-100 h-20"
            >
              <img
                src={AppleMusicLogo}
                className={classNames("w-12 h-12", "shrink-0")}
              />
            </a>

            <a
              href="https://www.abc.net.au/triplejunearthed/artist/ben-morgan/"
              className="p-3 px-6 hover:bg-stone-100 -ml-2.5"
            >
              <img
                src={TripleJLogo}
                className={classNames("w-12 h-12", "shrink-0")}
              />
            </a>
          </div>
        )}
      </section>

      <section className="gig-bg bg-fixed min-w-[30vh] md:min-h-[30vh] hidden md:block">
        <div className="flex justify-center">
          <h1 className="font-goon text-3xl md:text-4xl mt-20">Upcoming Gigs</h1>
        </div>
        <div className="flex flex-col mt-10 mb-10 font-playfairmedium text-xl items-center text-center">
          <a target="blank" href="https://www.bandsintown.com/a/626563-ben-morgan?came_from=253&utm_medium=web&utm_source=city_page&utm_campaign=search_bar">
            bandsintown.com
          </a>
        </div>
      </section>


      <div className="bg-fixed block texture-vert-bg md:hidden ">
        <div className="flex justify-center">
          <h1 className="font-goon text-3xl md:text-4xl mt-10">Upcoming Gigs</h1>
        </div>

        <div className="flex flex-col mt-10 mb-10 font-playfairmedium text-xl items-center text-center">
          <a target="blank" href="https://www.bandsintown.com/a/626563-ben-morgan?came_from=253&utm_medium=web&utm_source=city_page&utm_campaign=search_bar">
            bandsintown.com
          </a>
        </div>

        <h2 className="font-goon text-3xl tracking-widest text-center">
          Stay Up To Date
        </h2>
        <h4 className="font-old mt-2 tracking-wider text-center">
          Click Icons to Follow
        </h4>

        <div className="mt-12 flex flex-col items-center space-y-8">
          <IconLink
            src={InstagramLogo}
            link="https://www.instagram.com/benmorgan_/?hl=en"
            imgClassName="w-12"
          />
          <IconLink
            src={FacebookLogo}
            link="https://www.facebook.com/Ben-Morgan-551455264972877"
            imgClassName="w-12"
          />
          <IconLink
            src={SpotifyLogo}
            link="https://open.spotify.com/artist/0BGZx1OzVJ5uwHK4Ke5mNr"
            imgClassName="w-12"
          />
          <IconLink
            src={AppleMusicLogo}
            link="https://music.apple.com/au/artist/ben-morgan/1552341609"
            imgClassName="w-12"
          />
          <IconLink
            src={TripleJLogo}
            link="https://www.abc.net.au/triplejunearthed/artist/ben-morgan/"
            className="-ml-3"
            imgClassName="w-12"
          />
        </div>

        <div className="p-4 mt-16">
          <div className="relative">
            <img
              src={BenFireplace}
              className="absolute top-0 left-6 w-3/5 z-10"
            />
            <div className="h-12" />
            <img src={BenGuitar} className="w-2/5 ml-auto mr-1 z-0" />
          </div>

          <h2 className="mt-24 font-goon text-2xl tracking-widest">
            "Welcome Home Love. So The
          </h2>
          <h2 className="mb-24 font-goon text-2xl tracking-widest ml-12">
            Story Starts Again"
          </h2>

          <div className="relative">
            <img
              src={BenGuitarColor}
              className="absolute top-20 left-8 w-3/5 z-10"
            />
            <div className="h-12" />
            <img src={PalmTreeShadows} className="w-2/5 ml-auto mr-1 z-0" />
          </div>

          <div className="flex flex-col items-center my-24">
            <h2 className="font-goon text-2xl tracking-widest text-center">
              “No missing the beautiful serenity of this one from Ben. His
              emotional delivery is hard to not buy into”
            </h2>

            <h2 className="font-old w-fit italic text-sm text-center tracking-widest border-t-2 border-t-gray-400 mt-3 pt-3">
              - Dave Ruby Howe (Triple J)
            </h2>
          </div>

          <div className="relative">
            <img src={BenTree} className="absolute left-2 w-3/5 z-10" />
            <div className="h-24" />
            <img src={PalmTrees} className="w-3/5 ml-auto mr-1 z-0" />
          </div>

          <div className="flex flex-col items-center my-24">
            <h2 className="font-goon text-2xl tracking-widest text-center">
              "Beautiful fingerpicking and a folky vocal that has a bit of oomph
              to it too"
            </h2>

            <h2 className="font-old w-fit italic text-sm text-center tracking-widest border-t-2 border-t-gray-400 mt-3 pt-3">
              - Tommy Faith (Triple J)
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
